<template>
  <div class="tingXiaH">
    <div class="partyBuilding-main">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="item"
          @click="toYishiDetail(item)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="item-l">
            <div class="title">
              {{ item.description }}
            </div>
            <div class="item-lb">
              <div class="item-lbl">{{ item.insertTime }}</div>
              <div class="item-lbr">
                <div class="txt">{{ item.reactionUser }}</div>
              </div>
            </div>
          </div>
          <div class="item-r">
            <img
              :src="item.picture ? item.picture : require('./img/topic.png')"
              alt=""
            />
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getListForMiniURL } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "tingXiaH",
  data() {
    return {
      type: "",
      form: {
        donatePic: "",
        donateContent: "",
      },
      finishedText: "没有更多了",
      finished: false,
      pointsData: {
        totlePointCount: 0,
      },
      curPage: 1,
      pageSize: 10,
      listData: [],
      bannerList: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {},
  methods: {
    onLoad() {
      this.getPartyList();
    },
    toRedHousekeeper() {
      this.$router.push({
        name: "redUnion",
      });
    },
    toDetail(item) {
      let params = {
        id: item.id,
      };
      if (this.fromFlag == "dingding") {
        this.$router.push({
          name: "partyTopic",
          query: {
            id: item.id,
          },
        });
      } else {
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/partyInfoDetail/index?params=${JSON.stringify(
              params
            )}`,
          });
        }
      }
    },
    toYishiDetail(item) {
      let params = {
        id: item.id,
      };
      this.$router.push({
        name: "peopleReactionDetail",
        query: {
          id: item.id,
        },
      });
    },
    async getPartyList() {
      let res = await this.$axios.get(getListForMiniURL, {
        params: {
          tenantId: this.tenantId,
          userId: this.userId,
          curPage: this.curPage++,
          pageSize: this.pageSize,
        },
      });
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.listData = [];
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          this.listData.push(item);
        });
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tingXiaH {
  min-height: 100vh;
}
.tingXiaH {
  .partyBuilding-main {
    .item {
      padding: 20px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .item-l {
        flex: 1;
        margin-right: 38px;
        .title {
          font-size: 32px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 44px;
          margin-bottom: 28px;
        }
        .item-lb {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .item-lbl {
            font-size: 24px;
            font-weight: 400;
            color: #b4b4b4;
            line-height: 34px;
          }
          .item-lbr {
            display: flex;
            align-items: center;
            .img {
              width: 28px;
              height: 26px;
              margin-right: 4px;
              font-size: 0;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .txt {
              font-size: 24px;
              font-weight: 400;
              color: #b4b4b4;
              line-height: 34px;
            }
          }
        }
      }
      .item-r {
        width: 226px;
        height: 150px;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
